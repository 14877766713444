import {Component, Input, OnInit, OnChanges, SimpleChanges} from '@angular/core';

import { SponsorSettings } from '../shared/sponsor-settings.class';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit, OnChanges {
    private ZENDESK_SUPPORT_PAGE_LINK = 'https://' + environment.zendesk.url + '/hc/en-us';
    private COGNIGY_WEBCHAT
    @Input() sponsorSettings: SponsorSettings;
    supportLink = '';
    translatedChatLink

    constructor(
        private translateService: TranslateService
    ){}

    ngOnInit() {
        this.setSupportLink()
        this.translatedChatLink = this.translateService.get('enrollment.footer.chat');
        this.COGNIGY_WEBCHAT = document.createElement("script");
        this.COGNIGY_WEBCHAT.src = environment.cognigy.url;
        document.body.appendChild(this.COGNIGY_WEBCHAT);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['sponsorSettings'] && changes['sponsorSettings'].currentValue) {
            this.sponsorSettings = changes['sponsorSettings'].currentValue;
            this.setSupportLink();
        }
    }

    setSupportLink() {
        this.supportLink = this.sponsorSettings?.supportPageLink ? this.sponsorSettings.supportPageLink : this.ZENDESK_SUPPORT_PAGE_LINK;
    }
}
